<template>
  <article id="top">
    <header id="page-header" class="pt-10 pb-1 mb-5">
      <p id="company-title">{{this.companyName}}</p>
      <div class="horizontal-divider"></div>
      <v-col>
        <CompanyList/>
      </v-col>
    </header>
    <section v-if="pageLoading === true">
      <div class="loading-spinner-div">
        <v-progress-circular
          indeterminate
          :size="128"
          :width="12">
        </v-progress-circular>
        <h3 class="loading-spinner-text">
          Loading Member...
        </h3>
      </div>
    </section>
    <section v-if="pageError !== ''">
       <h1 class="text-center">{{ pageError }}</h1>
    </section>
    <section v-if="pageLoading === false && pageError === ''">
      <v-container fluid class="dashboard-container">
        <v-row>
          <v-col cols="12">
            <RevenueSpendHeader
              :revenue="htRevenue"
              :spend="htSpend"
              :show-restricted-company-fields="restrictedFields"
              :start-date="revenueStartDate"
              :end-date="revenueEndDate"
              :gpo-start="gpoStartDate"
              :gpo-end="gpoEndDate"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-card max-height="290" class="dash-card">
              <v-card-title class="justify-center font-weight-bold">Mission Statement</v-card-title>
              <v-card-text class="text-center">
                {{this.missionStatement}}
              </v-card-text>
            </v-card>
            <v-card class="dash-card">
              <v-card-title class="justify-center font-weight-bold"> Leadership &nbsp;
                <v-tooltip right>
                  <template v-slot:activator="{on, attrs}">
                    <v-icon class="mb-2" v-bind="attrs" v-on="on" @click="scrollTo('contact_table')">mdi-account-group</v-icon>
                  </template>
                  <span>Click to see a list of Contacts</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text class="text-center">
                <li v-for="contact in leadership" v-bind:key="contact.id">
                  {{ contact.name }}{{ contact.role != null && contact.role != '' ? ', ' + contact.role : null }}
                </li>
              </v-card-text>
            </v-card>
            <TextLabel class="dash-card" :display-values="demographics2"/>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="dash-card">
              <v-card-title class="justify-center"> Facilities </v-card-title>
              <MapChart :chart-data="facilityCount"/>
            </v-card>
            <TextLabel class="dash-card" :display-values="mapDemographics"/>
          </v-col>
          <v-col cols="12" md="4">
            <TextLabel class="dash-card" :display-values="demographics1"/>
            <DynamicsLabel class="dash-card" :display-values="importantAccountDynamics"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" class="little-card">
            <TextLabel :display-values="customerServiceIssues"/>
          </v-col>
          <v-col cols="12" md="3" class="little-card">
            <TextLabel :display-values="riskLevel"/>
          </v-col>
          <v-col cols="12" md="3" class="little-card">
            <TextLabel :display-values="lastQBR"/>
          </v-col>
          <v-col cols="12" md="3" class="little-card">
            <TextLabel :display-values="nextQBR"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4" class="little-card">
            <TextLabel :display-values="advisoryBoardSeats"/>
          </v-col>
          <v-col cols="12" md="4" class="little-card">
            <TextLabel :display-values="supplyChainBoardSeats"/>
          </v-col>
          <v-col cols="12" md="4" class="little-card">
            <TextLabel :display-values="physicianAdvisors"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <SpendGrowthChart/>
          </v-col>
          <v-col cols="12" md="6">
            <AdminFeesRebatesChart/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" id="kpi_table">
            <KpiTable v-if="mobile === false" :fiscal-year-start="FYStart" :fiscal-year-end="FYEnd"/>
            <KpiTableMobile v-else :fiscal-year-start="FYStart" :fiscal-year-end="FYEnd"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <ContractPerformance :ttmStart="gpoStartDate" :ttmEnd="gpoEndDate"/>
            <Footprint id="footprint_table"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <ExecutiveContacts :contact-data="contacts"/>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </article>
</template>

<script>
import dashboardService from '../services/dashboard.service';
import dates from '../dependencyAccess/dates/index';
import RevenueSpendHeader from '../components/RevenueSpendHeader.vue';
import TextLabel from '../components/TextLabel.vue';
import ContractPerformance from '../components/ContractPerformance.vue';
import Footprint from '../components/Footprint.vue';
import KpiTable from '../components/KpiTable.vue';
import MapChart from '../components/MapChart.vue';
import ExecutiveContacts from '../components/ExecutiveContacts.vue';
import SpendGrowthChart from '../components/SpendGrowthChart.vue';
import AdminFeesRebatesChart from '../components/AdminFeesRebatesChart.vue';
import DynamicsLabel from '../components/DynamicsLabel.vue';
import CompanyList from '../components/CompanyList.vue';
import KpiTableMobile from '../components/KpiTableMobile.vue';

export default {

  name: 'app',
  components: {
    RevenueSpendHeader,
    TextLabel,
    ContractPerformance,
    Footprint,
    KpiTable,
    MapChart,
    ExecutiveContacts,
    SpendGrowthChart,
    AdminFeesRebatesChart,
    DynamicsLabel,
    CompanyList,
    KpiTableMobile
  },
  data () {
    return {
      companyName: null,
      companyId: null,

      htRevenue: null,
      htSpend: null,
      restrictedFields: null,
      revenueStartDate: null,
      revenueEndDate: null,
      gpoStartDate: null,
      gpoEndDate: null,

      FYStart: null,
      FYEnd: null,
      demographics1: null,
      demographics2: null,
      mapDemographics: null,
      importantAccountDynamics: [],
      riskLevel: null,
      lastQBR: null,
      nextQBR: null,
      advisoryBoardSeats: null,
      supplyChainBoardSeats: null,
      customerServiceIssues: null,
      missionStatement: null,
      leadership: [],
      contacts: [],

      memberSearchItems: [],
      companyListLoading: false,
      memberSearch: '',

      spendGrowth: {},
      feesAndRebatesData: {},
      facilityCount: [],

      pageLoading: true,
      pageError: '',
      mobile: this.isMobile()
    };
  },
  mounted () {
    this.getDemographics();
  },
  methods: {
    getDemographics () {
      dashboardService.getOrganizationalInformationById(this.$route.params.entityType, this.$route.params.entityId).then((res) => {
        if (res.data.company === null) {
          return;
        } else {
          const USDollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0
          });
          this.companyId = res.data.company.companyId;
          this.companyName = res.data.company.name;

          this.htRevenue = res.data.company.healthTrustRevenue;
          this.htSpend = (res.data.company.healthTrustSpend !== null) ? res.data.company.healthTrustSpend : 'Awaitng Data Feed from Member';
          this.restrictedFields = res.data.company.showRestrictedCompanyFields;
          this.revenueStartDate = dates.datePrettyPrint(res.data.company.htRevenueStartDate);
          this.revenueEndDate = dates.datePrettyPrint(res.data.company.htRevenueEndDate);
          this.gpoStartDate = dates.datePrettyPrint(res.data.company.ttmStart);
          this.gpoEndDate = dates.datePrettyPrint(res.data.company.ttmEnd);
          this.FYStart = (res.data.company.fiscalYearStartDate) ? new Date(res.data.company.fiscalYearStartDate) : null;
          this.FYEnd = (res.data.company.fiscalYearEndDate) ? new Date(res.data.company.fiscalYearEndDate) : null;

          this.demographics1 = [
            { title: 'Number of S2 Agreements', value: res.data.company.numberOfS2Agreements },
            { title: 'Acct Mgt Sr Exec / Director', value: res.data.company.healthTrustAccountDirectorVP },
            { title: 'Admin Fee Share', value: (res.data.company.adminFeeShare !== null) ? (res.data.company.adminFeeShare * 100).toFixed(2) + '%' : '', tooltip: 'The agreed upon percentage of admin fee share that the vendors pay to HealthTrust and is shared with certain members. Source: Sourced by John Baker\'s team and loaded to the dashboard via spreadsheet ' },
            { title: 'Savings Guarantee', value: (res.data.company.savingsGuarantee === null) ? null : (res.data.company.savingsGuarantee >= 0) ? USDollar.format(res.data.company.savingsGuarantee) : '' },
            { title: 'Savings Guarantee Term', value: (res.data.company.savingsGuarantee > 0) ? res.data.company.savingsGuaranteeTerm : (res.data.company.savingsGuarantee === 0) ? 'N/A' : '', tooltip: '' },
            { title: 'Savings Guarantee Sign Off Date', value: (res.data.company.savingsGuarantee > 0) ? dates.datePrettyPrint(res.data.company.savingsGuaranteeSignOffDate) : (res.data.company.savingsGuarantee === 0) ? 'N/A' : '' }];
          this.mapDemographics = [
            { title: 'Number of Hospitals', value: (res.data.company.numberOfHospitals !== null) ? res.data.company.numberOfHospitals : null },
            { title: 'Number of ASCs', value: (res.data.company.numberOfASCs !== null) ? res.data.company.numberOfASCs : null },
            { title: 'Acquisitions in last 24 months', value: res.data.company.acquisitionsInLast24Months },
            { title: 'Divestitures in last 24 months', value: res.data.company.divestituresInLast24Months }];

          this.demographics2 = [
            { title: 'Years as a Member', value: res.data.company.memberYears },
            { title: 'PA Term', value: res.data.company.paTerm },
            { title: 'Auto Renew', value: res.data.company.membershipRenewalType },
            { title: 'Renewals with RFP', value: res.data.company.renewalsWithRFP },
            { title: 'Renewals without RFP', value: res.data.company.renewalsWithoutRFP },
            { title: 'Previous GPO', value: res.data.company.previousGPO }];

          if (res.data.company.importantAccountDynamics != null) {
            const dynamics = res.data.company.importantAccountDynamics.split(/\r?\n/);
            dynamics.forEach((item) => {
              if (item !== '') {
                this.importantAccountDynamics.push({ title: item });
              }
            });
          }

          this.missionStatement = res.data.company.missionStatement;
          this.riskLevel = [{ title: 'Risk Level', value: res.data.company.riskLevel }];
          this.lastQBR = [{ title: 'Last QBR', value: (res.data.company.lastQBRDate) ? dates.datePrettyPrint(res.data.company.lastQBRDate) : null }];
          this.nextQBR = [{ title: 'Next QBR', value: (res.data.company.nextQBRDate) ? dates.datePrettyPrint(res.data.company.nextQBRDate) : 'TBD' }];
          this.customerServiceIssues = [{ title: 'Open Customer Service Issues', value: res.data.company.customerServiceIssues }];
          this.advisoryBoardSeats = [{ title: 'Advisory Board Seats', value: 'Coming Soon', tooltip: '' }];
          this.supplyChainBoardSeats = [{ title: 'Supply Chain Board Seats', value: 'Coming Soon', tooltip: '' }];
          this.physicianAdvisors = [{ title: 'Physician Advisors', value: 'Coming Soon', tooltip: '' }];
          this.contacts = res.data.contact;
          this.facilityCount = res.data.facilityCountByState;

          // Filter the list by unique names and roles
          res.data.contact.filter((value, index, self) => {
            return self.findIndex(v => v.name === value.name && v.role === value.role) === index;
          }).forEach((contact) => {
            if (contact.rank >= 1 && contact.rank <= 5) {
              this.leadership.push(contact);
            }
          });
        }
        this.pageLoading = false;
      }, (reason) => {
        this.pageLoading = false;
        this.pageError = reason;
      });
    },

    scrollTo (id) {
      document.getElementById(id).scrollIntoView();
    },
    isMobile () {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];
      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
    }
  }
};

</script>

<style scoped lang="scss">

.dash-card {
  margin-top: 12px;
  margin-bottom: 12px;
}
.little-card {
  text-align: center;
}
#company-title {
  font-size: 52px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: -1.7px;
  text-align: center;
  color: #1E345D;
}
#page-header {
  background: white;
}
.dashboard-container {
  margin-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
}
.horizontal-divider {
  width: 80px;
  height: 4px;
  margin: auto;
  /* Orange on Light */
  background: #DB7100;
}
.table-cell {
  vertical-align: middle;
  text-align: left;
}
.row-pointer {
  cursor: default;
}
li {
  list-style-type: none;
}
::v-deep #contact-table {
  .v-data-table-header {
    th {
      background-color: $blue;
      color: white;
      font-weight: 700;
      font-size: 15px;
      white-space: nowrap;
      padding: 15px;
      border-left: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
    }
  }
  td {
    padding-top: 10px;
    padding-bottom: 5px;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  .v-data-table__mobile-table-row {
    border-top: 2px solid #dddddd;
    td {
      border-bottom: 0px;
    }
  }
}
.pagination-div {
  padding-top: 2px;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}
.loading-spinner-div {
  text-align: center;
  margin-top: 10%;
}
.loading-spinner-text{
    text-align: center;
    margin-top: 50px;
    color: $blue;
  }
.v-progress-circular {
  color: #1E345D
}
</style>
